<template>

  <div>
    <div class="title">
      短信计划
    </div>
    <div class="main">

      <PlanItem class="plan-template" v-for="(item, index) in planTemplateData"
                :img_icon="item.icon"
                :title="item.name"
                @go="go(index)"
                :sub-title="item.desc"/>
      <div class="my-plan-header">
        <div class="my-plan-header-text">我的短信计划</div>
        <van-divider class="my-plan-header-divider"/>
      </div>

      <MyPlanItem v-for="(item, index) in planList"
                  :img_icon="item.icon"
                  :title="item.name"
                  @goManage="goManage(index)"
                  :sub_title="item.desc"/>

    </div>
  </div>
</template>

<script>
import {Button, Divider} from 'vant';
import PlanItem from "../../components/plan/PlanItem";
import MyPlanItem from "../../components/plan/MyPlanItem";
import {getPlanTemplate, getPlanList} from "../../api/sms";

export default {
  name: "plan",
  data() {
    return {
      icon: 'https://cdn3.codesign.qq.com/dcloud/attachments/2022/02/24/LxxUyT5sKnTXMx0Cd8ROULdTQhk3H5dcJjNPiCGI-o.png',
      clientHeight: '',
      planTemplateData: [],
      planList: []
    }
  },
  components: {
    [Button.name]: Button,
    [Divider.name]: Divider,
    PlanItem,
    MyPlanItem,
  },
  created() {

    getPlanTemplate()
        .then(rsp => {
          this.planTemplateData = rsp.data.data;
        })

    getPlanList()
        .then(res => {
          // console.log(res.data.data)
          // if (res.data.code === '00000') {
            this.planList = res.data.data;
          // }
        })
  },
  methods: {
    getList() {
      getPlanTemplate()
          .then(rsp => {
            this.planTemplateData = rsp.data.data;
          })
    },
    go(index) {
      let id = this.planTemplateData[index].id
      let name = this.planTemplateData[index].name
      this.$router.push({
        path: '/plan/create',
        query: {
          id: id,
          name: name,
        }
      })
    },
    goManage(index) {
      let id = this.planList[index].id
      // let id = 1
      this.$router.push({
        path: '/plan/manage',
        query: {
          id: id,
        }
      })
    }
  }


}
</script>

<style scoped>

.main {
  background: #f7f7f7;
  /*padding: 0 15px ;*/
  padding: 0 15px 65px 15px;
  height: 100%;
  box-sizing: border-box;
}

.title {
  font-size: 22px;
  color: #292833;
  font-weight: bold;
  height: 40px;
  padding: 15px;
  line-height: 40px;
  z-index: 999;
  background: #f7f7f7;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.my-plan-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.my-plan-header-text {
  width: 140px;
  color: #9ca3af;
  font-size: 14px;
}

.my-plan-header-divider {
  width: 100%;
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  margin-left: 20px;
}


</style>