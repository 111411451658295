<template>
<div class="item">
  <div class="item-img">
    <img :src="img_icon" class="img-icon">
  </div>
  <div class="title-content">
    <div class="title">{{title}}</div>
    <div class="sub-title">{{ sub_title }}</div>
  </div>
  <div class="btn-content">
    <van-button class="btn-content" type="primary" v-on:click="$emit('goManage')" plain round  >
      <span class="span-text">管理</span>
    </van-button>
  </div>
</div>
</template>

<script>
import {Button} from 'vant';
import dayjs from "dayjs";
export default {
  name: "MyPlanItem",
  props: {
    img_icon:String,
    title: String,
    sub_title: String,
  },
  emits: ['goManage'],
  components: {
    [Button.name]: Button
  }
}
</script>

<style scoped>
.item{
  height: 80px;
  width: 100%;
  border-radius: 8px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 16px 12px;
  margin: 8px 0;

}
.item-img{
  margin-right: 16px;

}
.img-icon{
  width: 48px;
  height: 48px;
  border-radius: 48px;
  box-sizing: border-box;
}
.title-content{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.title{
  font-size: 16px;
  color: #292833;
  font-weight: bold;
}

.sub-title{
  color: #9ca3af;
  font-size: 12px;
  margin-top: 6px;
}

.btn-content{
  width: 68px;
  height: 30px;
  --tw-border-opacity: 1!important;
  border-color: rgba(229,231,235,var(--tw-border-opacity))!important;

}
.span-text{
  font-size: 10px;
  color: #6b7280;
}
</style>